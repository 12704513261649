<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">批量设置灯杆参数</div>
    <div class="import-container">
      <Form ref="form" :label-width="100" class="import-form">
        <FormItem v-if="item.type == 'height'" prop="height" :label="showLang('com.import.cn.pole.height')">
          <!-- label="灯杆高度" -->
          <Input type="number" v-model="form.height"><span slot="append">米</span></Input>
        </FormItem>
        <FormItem v-if="item.type == 'armCount'" prop="height" label="杆臂数量">
          <Select :placeholder="showLang('save.select')" v-model="form.armCount" style="margin-right: 36px;">
            <template v-for="n in 20">
              <Option :value="n" :key="n">{{n}}</Option>
            </template>
          </Select>
        </FormItem>
        <FormItem v-if="item.type == 'group'" label="所在分区" style="margin-right: 15px">
          <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="margin-right: 36px;">
            <template v-for="(item, idx) in allGroups">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button type="success" style="margin-left: 8px;height: 40px;" :loading="loading" @click="ok">设置参数</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapGetters,mapState } from 'vuex'
export default {
  name: 'ModalSetArgs',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      form: {
        height: 8,
        armCount:1,
        groupId:0,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      // console.log('all groups', this.groupTreeSelectedNode)
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.form.height = 8;
        this.form.armCount = 1;
        this.form.groupId = 0;
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    ok: async function () {
      let args = null;
      switch (this.item.type) {
        case 'height': args = { cmd: this.item.type, args: {height: this.form.height} }; break;
        case 'armCount': args = { cmd: this.item.type, args: {armCount: this.form.armCount }}; break;
        case 'group': args = { cmd: this.item.type, args: {groupId: this.form.groupId }}; break;
        default: {
          this.$Message.warning('非法参数类型');
          return
        }
      }
      this.$emit('saved', args);
      this.showModal = false;
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}

.import-container {
  /* border: solid 1px red; */
  /* height: 100%; */
  display: flex;
}
.import-form {
  width: 350px;
  flex: none;
}
.import-data {
  width: 500px;
  flex: auto;
  margin-left: 35px;
}
.logo-upload {
  width: 100%;
  height: 40px;
  border: solid 1px #dcdee2;
  cursor: pointer;
}
.logo-upload input {
  /* visibility: hidden; */
  width: 100%;
}
.logo-upload button {
  /* visibility: hidden; */
  width: 100%;
  height: 40px;
}
</style>