<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('com.export.fn.pole')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="code" :label="showLang('com.device.pole.code')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" disabled v-model="form.code" :placeholder="showLang('com.sys.generate')"></Input>
        </div>
      </FormItem>
      <FormItem prop="groupId" label="所在分区" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="margin-right: 36px;">
          <template v-for="(item, idx) in allGroups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="height" :label="showLang('com.import.cn.pole.height')" style="margin-right: 15px">
        <Input type="number" number v-model="form.height">
        <span slot="append">M</span>
        </Input>
      </FormItem>
      <FormItem prop="armCount" label="杆臂数量" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.armCount" style="margin-right: 36px;">
          <template v-for="n in 20">
            <Option :value="n" :key="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="lng" :label="showLang('com.import.cn.lng')">
        <Input ref="lng" type="text" v-model="form.lng" :placeholder="showLang('import.lng.installation.location')" style="width: 270px;margin-right: 10px">
        </Input>
        <Button type="primary" @click="selectPos">选择</Button>
      </FormItem>
      <FormItem prop="lat" :label="showLang('com.import.cn.lat')" style="margin-right: 15px">
        <Input ref="lat" type="text" v-model="form.lat" :placeholder="showLang('import.lat.installation.location')">
        </Input>
      </FormItem>
      <FormItem prop="buildDate" :label="showLang('com.date.installation')">
        <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
      </FormItem>
      <FormItem prop="location" label="安装位置" style="margin-right: 15px">
        <Input type="text" v-model="form.location" placeholder=""></Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
    <ModalSelectMapPoint v-model="showMapModal" :custCenter="pos" @posSelected="posSelected" />
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalSelectMapPoint from '@/views/common/modals/ModalSelectMapPoint'
export default {
  name: 'ModalPoleEdit',
  components: {
    ModalSelectMapPoint,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      showMapModal: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      pos: {
        area: '',
        lat: 0,
        lng: 0,
      },
      form: {
        id: '',
        groupId: 0,
        code: '',
        name: '',
        armCount: 1,
        height: 8,
        location: '',
        buildDate: '',
        lng: 122,
        lat: 23,
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if (!this.isAdd) {
          // debugger;
          this.form.id = this.item.data.id;
          this.form.groupId = this.item.data.groupId;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.id;
          this.form.armCount = this.item.data.armCount;
          this.form.lng = this.item.data.lng;
          this.form.lat = this.item.data.lat;
          this.form.height = this.item.data.height;
          this.form.location = this.item.data.location;
          this.form.buildDate = this.item.data.buildDate;
        } else {
          this.form.id = '';
          this.form.groupId = this.allGroups.length > 0 ? this.allGroups[0].id : 0;
          this.form.armCount = 1;
          this.form.name = '';
          this.form.code = '自动生成';
          this.form.lng = this.config.lng;
          this.form.lat = this.config.lat;
          this.form.height = 8;
          this.form.location = '';
          this.form.buildDate = new Date().format('yyyy-MM-dd');
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['config']),
    ...mapGetters('auth', ['showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      // console.log('all groups', this.groupTreeSelectedNode)
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  mounted: function () {
  },
  methods: {
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    selectPos: function(){
      let lat = this.form.lat;
      let lng = this.form.lng;
      if(!lat){
        lat = this.config.lat;
        lng = this.config.lng;
      }
      this.pos = {
        area: this.config.area,
        lat: lat,
        lng: lng,
      };
      this.showMapModal = true;
    },
    posSelected: function(params){
      // console.log('pos sel', params, this.config);
      this.form.lat = params.lat;
      this.form.lng = params.lng;
    },
    ok: async function () {
      if (this.form.name.trim().length == 0) {
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if (this.form.groupId <= 0) {
        this.$Message.warning('请选择所在分区');
        return;
      }
      this.$axios.post(`common/pole/SavePole`, this.form).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', { isAdd: this.isAdd, data: res.data });
          window.eventBus.$emit('poleChanged');
        }
      })
    },
    cancel() {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
</style>