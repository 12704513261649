<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">{{showLang('save.batch.pole.info')}}</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="groupId" label="所在分区" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.groupId" style="margin-right: 36px;">
          <template v-for="(item, idx) in allGroups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="height" :label="showLang('com.import.cn.pole.height')" style="margin-right: 15px">
        <Input type="number" number v-model="form.height">
        <span slot="append">M</span>
        </Input>
      </FormItem>
      <FormItem prop="armCount" label="杆臂数量" style="margin-right: 15px">
        <Select :placeholder="showLang('save.select')" v-model="form.armCount" style="margin-right: 36px;">
          <template v-for="n in 20">
            <Option :value="n" :key="n">{{n}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="name" :label="showLang('com.tab.prefix.name')" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" :placeholder="showLang('com.tab.title')"></Input>
        </div>
      </FormItem>
      <FormItem prop="start" :label="showLang('com.tab.suffix.num')" style="margin-right: 15px">
        <div style="display: flex;">
          <Input type="number" number v-model="form.start">
            <span slot="prepend">{{showLang('com.op.start')}}</span>
          </Input>
          <Input type="number" number v-model="form.count">
            <span slot="prepend">{{showLang('com.num')}}</span>
            <span slot="append">{{showLang('com.device.pole.num')}}</span>
          </Input>
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalBatchAdd',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        groupId: 0,
        name: '',
        start: 0,
        count: 0,
        armCount: 1,
        height: 8, //高度
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.id = 0;
        this.form.groupId = this.allGroups.length > 0 ? this.allGroups[0].id : 0;
        this.form.name = '';
        this.form.start = 0;
        this.form.count = 0;
        this.form.height = 8;
        this.form.armCount = 1;
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('group', ['groupTreeSelectedNode']),
    allGroups: function(){
      // console.log('all groups', this.groupTreeSelectedNode)
      if(!this.groupTreeSelectedNode.children)return[];
      let grps = [];
      if(this.groupTreeSelectedNode.id > 0){
        grps.push({id: this.groupTreeSelectedNode.id, name: this.groupTreeSelectedNode.title});
      }
      for(let node of this.groupTreeSelectedNode.children){
        this.getChildGroups(grps, node);
      }
      return grps;
    },
  },
  mounted: function(){
  },
  methods: {
    getChildGroups: function(list, node){
      list.push({id: node.id, name: node.title});
      for(let n of node.children){
        this.getChildGroups(list, n);
      }
    },
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning(this.showLang('save.name'));
        return;
      }
      if(this.form.groupId <= 0){
        this.$Message.warning(this.showLang('save.site.associated'));
        return;
      }
      this.$axios.post(`common/pole/FastSavePole`, this.form).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
          window.eventBus.$emit('poleChanged');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>